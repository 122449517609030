import { useAuthStore } from '@/modules/auth/store/auth';
import { WORKER } from '@/utils/enums/UserTypes';

export async function loginCheck(onSelectType: boolean = false) {
  const {
    user,
    isClient,
    isWorker,
    hasCompletedOnboarding,
    hasPersonalProfile,
    hasAvatarProfile,
    hasSupportRequirements,
    hasQualificationsNeeded,
    hasRequireSupportSchedule,
    hasSupportPreference,
    hasServicesOffered,
    hasAvailabilitySchedule,
    hasIndicativeRates,
    hasClientPreference,
    hasLoggedInUser,
  } = useAuthStore();

  if (!user.onboarded_at) {
    if (!user.user_type || onSelectType) {
      await navigateTo({ name: 'onboarding-welcome' }, { replace: true });
      return false;
    }

    if (!user.fo_verification) {
      await navigateTo({ name: 'onboarding-id-selector' }, { replace: true });
      return false;
    }

    if (!hasPersonalProfile) {
      await navigateTo({ name: 'onboarding', query: { form: 'personal-profile' } }, { replace: true });
      return false;
    }

    if (!hasAvatarProfile) {
      await navigateTo({ name: 'onboarding', query: { form: 'your-profile' } }, { replace: true });
      return false;
    }

    if (!hasCompletedOnboarding) {
      if (isClient) {
        if (!user.documents.length) {
          window.location.href = `/onboarding?form=id-verification`;
          return false;
        }

        if (!hasSupportRequirements) {
          await navigateTo({ name: 'onboarding', query: { form: 'support-requirements' } }, { replace: true });
          return false;
        }

        if (!hasQualificationsNeeded) {
          await navigateTo({ name: 'onboarding', query: { form: 'qualifications-needed' } }, { replace: true });
          return false;
        }

        if (!hasRequireSupportSchedule) {
          await navigateTo({ name: 'onboarding', query: { form: 'work-hours' } }, { replace: true });
          return false;
        }

        if (!hasSupportPreference) {
          await navigateTo({ name: 'onboarding', query: { form: 'support-preferences' } }, { replace: true });
          return false;
        }
      } else {
        if (!hasServicesOffered) {
          await navigateTo({ name: 'onboarding', query: { form: 'service-offerings' } }, { replace: true });
          return false;
        }

        if (!hasAvailabilitySchedule) {
          await navigateTo({ name: 'onboarding', query: { form: 'availability' } }, { replace: true });
          return false;
        }

        if (!hasIndicativeRates) {
          await navigateTo({ name: 'onboarding', query: { form: 'rates' } }, { replace: true });
          return false;
        }

        if (!hasClientPreference) {
          await navigateTo({ name: 'onboarding', query: { form: 'client-preferences' } }, { replace: true });
          return false;
        }

        if (!user.documents.length) {
          await navigateTo({ name: 'onboarding', query: { form: 'required-documents' } }, { replace: true });
          return false;
        }

        if (!user.educations.length) {
          await navigateTo({ name: 'onboarding', query: { form: 'education' } }, { replace: true });
          return false;
        }

        if (!user.qualifications.length) {
          await navigateTo({ name: 'onboarding', query: { form: 'training-and-qualifications' } }, { replace: true });
          return false;
        }

        if (!user.work_experiences.length) {
          await navigateTo({ name: 'onboarding', query: { form: 'work-experience' } }, { replace: true });
          return false;
        }

        if (!user.subscribed) {
          await navigateTo({ name: 'onboarding', query: { form: 'membership' } }, { replace: true });
          return false;
        }
      }
    }
  }

  const { options, setOptions } = useAuthStore();
  if (!user.subscribed && user.user_type === WORKER && !options.skipSubscription) {
    setOptions({ skipSubscription: true });
    await navigateTo({ name: 'onboarding', query: { form: 'membership' } }, { replace: true });
    return false;
  }

  return true;
}
